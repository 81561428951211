@import './themes';

@mixin bootstrap-rtl() {
  .btn-group:not(.btn-divided-group) > .btn:not(.dropdown-toggle) {
    &:first-child {
      @include nb-ltr() {
        border-top-left-radius: nb-theme(btn-border-radius);
        border-bottom-left-radius: nb-theme(btn-border-radius);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      };
      @include nb-rtl() {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: nb-theme(btn-border-radius);
        border-bottom-right-radius: nb-theme(btn-border-radius);
      };
    }
    &:last-child {
      @include nb-ltr() {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: nb-theme(btn-border-radius);
        border-bottom-right-radius: nb-theme(btn-border-radius);
      };
      @include nb-rtl() {
        border-top-left-radius: nb-theme(btn-border-radius);
        border-bottom-left-radius: nb-theme(btn-border-radius);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      };
    }
  }

  .btn-group.dropdown {
    & > .btn:first-of-type.dropdown-toggle {
      @include nb-ltr() {
        border-top-left-radius: nb-theme(btn-border-radius);
        border-top-right-radius: 0;
      };
      @include nb-rtl() {
        border-top-left-radius: 0;
        border-top-right-radius: nb-theme(btn-border-radius);
      };
    }
    & > .btn:last-of-type.dropdown-toggle {
      @include nb-ltr() {
        border-top-left-radius: 0;
        border-top-right-radius: nb-theme(btn-border-radius);
      };
      @include nb-rtl() {
        border-top-left-radius: nb-theme(btn-border-radius);
        border-top-right-radius: 0;
      };
    }

    &:not(.show) {
      & > .btn:first-of-type.dropdown-toggle {
        @include nb-ltr() {
          border-bottom-left-radius: nb-theme(btn-border-radius);
          border-bottom-right-radius: 0;
        };
        @include nb-rtl() {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: nb-theme(btn-border-radius);
        };
      }
      & > .btn:last-of-type.dropdown-toggle {
        @include nb-ltr() {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: nb-theme(btn-border-radius);
        };
        @include nb-rtl() {
          border-bottom-left-radius: nb-theme(btn-border-radius);
          border-bottom-right-radius: 0;
        };
      }
    }
  }

  .btn-group.dropup {
    & > .btn:first-of-type.dropdown-toggle {
      @include nb-ltr() {
        border-bottom-left-radius: nb-theme(btn-border-radius);
        border-bottom-right-radius: 0;
      };
      @include nb-rtl() {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: nb-theme(btn-border-radius);
      };
    }
    & > .btn:last-of-type.dropdown-toggle {
      @include nb-ltr() {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: nb-theme(btn-border-radius);
      };
      @include nb-rtl() {
        border-bottom-left-radius: nb-theme(btn-border-radius);
        border-bottom-right-radius: 0;
      };
    }

    &:not(.show) {
      & > .btn:first-of-type.dropdown-toggle {
        @include nb-ltr() {
          border-top-left-radius: nb-theme(btn-border-radius);
          border-top-right-radius: 0;
        };
        @include nb-rtl() {
          border-top-left-radius: 0;
          border-top-right-radius: nb-theme(btn-border-radius);
        };
      }
      & > .btn:last-of-type.dropdown-toggle {
        @include nb-ltr() {
          border-top-left-radius: 0;
          border-top-right-radius: nb-theme(btn-border-radius);
        };
        @include nb-rtl() {
          border-top-left-radius: nb-theme(btn-border-radius);
          border-top-right-radius: 0;
        };
      }
    }
  }

  .btn-divided-group {
    .btn:not(:first-child) {
      @include nb-ltr(margin-left, 0.5rem);
      @include nb-rtl(margin-right, 0.5rem);
      border-radius: nb-theme(btn-border-radius);
    }
  }

  .input-group-addon,
  .input-group-icon {
    @include nb-ltr() {
      border-left: nb-theme(form-control-border);
      border-right: none;
    };
    @include nb-rtl() {
      border-left: none;
      border-right: nb-theme(form-control-border);
    };
  }

  .input-group {
    .form-control:first-child:not(:only-child),
    .input-group-addon:first-child,
    .input-group-prepend .btn:first-child,
    .input-group-btn .btn:first-child {
      @include nb-ltr() {
        border-top-left-radius: nb-theme(form-control-border-radius);
        border-bottom-left-radius: nb-theme(form-control-border-radius);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      };
      @include nb-rtl() {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: nb-theme(form-control-border-radius);
        border-bottom-right-radius: nb-theme(form-control-border-radius);
      };
    }
    .form-control:last-child:not(:only-child),
    .input-group-addon:last-child,
    .input-group-append .btn:last-child,
    .input-group-btn .btn:last-child {
      @include nb-ltr() {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: nb-theme(form-control-border-radius);
        border-bottom-right-radius: nb-theme(form-control-border-radius);
      };
      @include nb-rtl() {
        border-top-left-radius: nb-theme(form-control-border-radius);
        border-bottom-left-radius: nb-theme(form-control-border-radius);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      };
    }

    .dropdown.show .btn.dropdown-toggle {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .dropup.show .btn.dropdown-toggle {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
