@import '~@angular/material/prebuilt-themes/indigo-pink.css';
/* @import "~ag-grid/dist/styles/ag-grid.css";
@import "~ag-grid/dist/styles/ag-theme-balham.css"; */

@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

@import '../node_modules/ngx-toastr/toastr.css';
@import "~ng-pick-datetime/assets/style/picker.min.css";


/* ag grid customer style */
.ag-theme-alpine .ag-row-odd {
  background-color: #f3f3f3 !important;
}
/* .ag-theme-balham .ag-row {
  font: 500 17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif i !important;
  font-size: 14px !important;
}

.ag-theme-balham .ag-row-odd {
  background-color: #f3f3f3 !important;
} */

.input-headline {
  font-size: 28px;
  line-height: 32px;
  font-weight: 400;
}

body { 
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
}

.basic-container {
  padding: 30px;
}

.version-info {
  font-size: 8pt;
  float: right;
}

.mat-radiobutton-label-top{
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;    
  font-size: 0.8rem;
  font-weight: 400;
  font-family: Roboto,"Helvetica Neue",sans-serif;
}

.mat-radiobutton-label-top-long{
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;    
  font-size: 0.8rem;
  font-weight: 350;
  font-family: Roboto,"Helvetica Neue",sans-serif;
}

@media print {
  nb-layout-header{
    display:none !important;
  }
  nb-sidebar{
    display:none !important;
  }
}

/* -----Font Awesome icon effect----- */
.fa-rotate-30 {
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}

/* .fa:hover {
  color:  #009FFF;
  background-color: #fff;
  transform: rotate(360deg) scale(1.5);
} */

.fa.rotate-effect {
   display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  font-size-adjust: none;
  font-stretch: normal;
  font-feature-settings: normal;
  font-language-override: normal;
  /* font-size: 7em; */
  text-rendering: auto;
  /* margin-top: 14px; */
  transition: all .65s linear 0s;
  text-align: center;
  background-color: #fff;
  border-radius: 90px;
  transition: all 1s cubic-bezier(.99,.82,.11,1.41);
}

.fa.rotate-effect:hover {
  /* color: #00fbf4;
  background-color: #fff; */
  transform: rotate(360deg) scale(1.5);
}

/* -----Font Awesome icon effect - END----- */


/* -----File Upload with icon - START----- */
.btn-fileUpload {
  position: relative;
  overflow: hidden;
}
.btn-fileUpload input[type=file]{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  /* min-width: 100px; */
  min-height: 100%;
  font-size: 100%;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;   
  cursor: inherit;
  display: block;
}
/* -----File Upload with icon - END----- */

/* -----Angular material table header font weight - START----- */
.mat-header-cell {
  font-size: 12px !important;
  font-weight: 600 !important;
}
/* -----Angular material table header font weight - END----- */

.nb-theme-default nb-layout .layout nb-layout-header nav{
  /* height: 4.00rem !important; */
}

.nb-theme-default nb-layout .layout nb-layout-header.fixed ~ .layout-container{
  /* height: 4.00rem !important;
  padding-top:4.00rem !important; */
}
.nb-theme-default nb-layout .layout .layout-container .content .columns nb-layout-column{
  padding: 1.0rem 1.0rem 0.0rem !important;
}

.nb-theme-default nb-layout .layout .layout-container nb-sidebar.fixed {
  top: 20.0rem;
}

.nb-theme-default nb-sidebar .scrollable{
  padding-top: 0 !important;
}

.menu-sidebar{
  background-color: white !important;
}

.nb-theme-default nb-menu .menu-item .menu-icon {
    font-weight: 600;
    color: #8f9bb3;
    font-size: 2.2rem !important;
    margin: 0 0.5rem 0 0;
    width: 1.15em;
    text-align: center;
    font-weight: 100;
}

.nb-theme-default nb-menu .menu-group, .nb-theme-default nb-menu .menu-item a {
  font-family: Open Sans, sans-serif, Roboto;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0.75rem 1rem;
  background-color: white !important;
}

.nb-theme-default nb-user .user-picture {
  background-color: transparent !important;
  border: 1px solid #9eacc1 !important;
}